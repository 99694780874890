import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Gist from 'react-gist';
import '../assets/css/style.css';
import CodeHighlighter from "../components/CodeHighlighter"
import { curlCommand } from "."
import { EmailShowCase } from "../components/EmailShowCase"
import {AtSymbolIcon, CheckCircleIcon, CloudUploadIcon, ColorSwatchIcon, DesktopComputerIcon, DeviceMobileIcon, LightningBoltIcon} from '@heroicons/react/outline'
const IndexPage = () => (
  <Layout  pageId="emaildesigner">
    <SEO title="Online email design editor to build responsive emails" description={'Design good looking emails using drag and drop editor. Improve your email conversions rates with mobile friendly design that doesnt break. Connect with Amazon SES.'} />
    <div className=" hero-back align-center">
      <div className="max-w-6xl mx-auto pt4">


      <div className="flex flex-wrap items-center feat-blu pb-2">
        
        <div className="px-8 md:px-0 text-center w-full pt-20">
          <h1 className=" font-medium md:text-5xl text-3xl leading-tight mb-4 text-blue-750">Design emails that people love to take action</h1>
          <h2 className="display text-gray-600 text-lg pb2 leading-normal mb-6">Improve your email conversion with aesthetically appealing designs.</h2>
          <div className="hero-buttons mt1">
            <a href='https://app.emailkick.com/register' rel="nofollow" className=" bg-red-500 hover:bg-red-600 text-white strong px-6 py-4 text-base font-medium rounded-xl  duration-100 shadow-lg">Get started for free</a>

            {/* <a className="ed-btn primary-cta mr3">Start Sending</a> */}
            {/* <a className="sec-cta">Watch Video</a> */}
          </div>

        </div>   

        <EmailShowCase />     
      </div>


        <div className="hero text-center">

         

         
        </div>  



      
      </div>

      
    </div>

 







    <section className="feat-section mt-0 md:py-12 py-2" >

<div className="max-w-6xl mx-auto">
    {/* <div className="relative z-0">
        <img alt="" className="absolute transform scale-100 -mt-24" src="/images/blurred_2.png" />
        <img alt="" className="absolute transform md:scale-150 right-2 -mt-24 opacity-50" src="/images/blurred_2.png" />
    </div>   */}

    <div className="grid md:grid-cols-3 gap-4 relative z-10">
      <div className="bg-white bg-opacity-80 glassbox-non-radius rounded-xl md:py-6 py-2 md:px-0 px-4" >
      <span><ColorSwatchIcon  className="w-10 mb-2 text-blue-600" /></span>
        <h3 className="text-lg mb-2 font-medium">Design block System</h3>
        <p className="text-sm leading-relaxed text-gray-600">Work with pre-designed block to create emails faster</p>
      </div>

      <div className="bg-white bg-opacity-80 glassbox-non-radius rounded-xl md:py-6 py-2 md:px-0 px-4 " >
      <span><LightningBoltIcon  className="w-10 mb-2 text-blue-600" /></span>
        <h3 className="text-lg mb-2 font-medium">Manage Your Content</h3>
        <p className="text-sm leading-relaxed text-gray-600">Add your text, links and images to any pre-designed modules using the drag and drop editor.</p>
      </div>


      <div className="bg-white bg-opacity-80 glassbox-non-radius rounded-xl md:py-6 py-2 md:px-0 px-4 " >
      <span><DeviceMobileIcon  className="w-10 mb-2 text-blue-600" /></span>
        <h3 className="text-lg mb-2 font-medium">Mobile Friendly</h3>
        <p className="text-sm leading-relaxed text-gray-600">Your emails will look great on both desktop and mobile</p>
      </div>

      <div className="bg-white bg-opacity-80 glassbox-non-radius rounded-xl md:py-6 py-2 md:px-0 px-4" >
        <span><CloudUploadIcon  className="w-10 mb-2 text-blue-600" /></span>
        <h3 className="text-lg mb-2 font-medium">Cloud Image Hosting</h3>
        <p className="text-sm leading-relaxed text-gray-600">Host images on our server and optimise.</p>
      </div>

      <div className="bg-white bg-opacity-80 glassbox-non-radius rounded-xl md:py-6 py-2 md:px-0 px-4  " >
      <span><AtSymbolIcon  className="w-10 mb-2 text-blue-600" /></span>

        <h3 className="text-lg mb-2 font-medium">On demand Email Preview</h3>
        <p className="text-sm leading-relaxed text-gray-600">Check and confirm email designs before sending.</p>
      </div>

      <div className="bg-white bg-opacity-80 glassbox-non-radius rounded-xl md:py-6 py-2 md:px-0 px-4" >
       <span><DesktopComputerIcon  className="w-10 mb-2 text-blue-600" /></span>
        <h3 className="text-lg mb-2 font-medium">Retina Ready</h3>
        <p className="text-sm leading-relaxed text-gray-600">No more blurry images. Your graphics and texts stay sharp!</p>
      </div>





     
    </div>
</div>

</section>



<section className="feat-section mt-16 bg-gray-900 md:py-20 py-8"> 
  <div className="flex flex-wrap justify-between max-w-6xl mx-auto px-4 md:px-0">
    <div className="md-col-4 md:pr-8">
      <h1 className="display strong md:text-4xl text-3xl line-height-3  text-white">Works on all the email clients</h1>
      <p className="md:text-lg text-base quiet mt-3 font-normal text-gray-300">We have designed and tested our design blocks to work on all the top email clients so that you don't have to worry about it.</p>
    </div>
    <div className="md-col-5 mt-4 md:mt-0">
      <div className="grid md:grid-cols-2 grid-cols-3 gap-4 ">
        {
         ['Outlook', 'Gmail', 'Yahoo mail', 'Gmail Android', 'Apple Mail', 'AOL Mail', 'Office 365', 'iPad Mail', 'iPhone Mail', 'Android', 'Windows Mail', 'Superhuman', 'Front'].map(mclient => (
            <div className="flex ">
              <span className="mr-2"><CheckCircleIcon className="md:w-6 w-5 text-green-500" /></span>
              <span className="md:text-base text-sm text-white">{mclient}</span>
            </div>
         ))
        }

      </div>
    </div>

  </div>
</section>



<section className=" pb-8 mt-16" >
      <div className="max-w-6xl mx-auto">
        <div className="flex flex-wrap items-center justify-between feat-blu py2">
        
          <div className="md-col-4 md:pr-10 px-4 md:pl-0">
            <h2 className="mb2 display strong md:text-4xl  text-3xl line-height-3  highlight-text">Made for all modern<br /> screen sizes.</h2>
            <h3 className="quiet display md:text-lg text-base line-height-4 mb2">Managing styles and HTML that work across different clients is hard and time-consuming. We'll help you manage these workloads and make them simple.</h3>
          </div>

          <div className="md-col-5 flex px-4 md:px-0">
            {/* <Gist id={'e8478428980695c900ce00d51bcb3e8f'} file={'gistfile1.txt'} /> */}
            {/* <img className='col-12' src={'/emails-04.png'} /> */}
            <div className="w-full  rounded-2xl bg-white bg-opacity-90 relative z-20 glassbox-non-radius">
              <img alt="responsive email" style={{ height:'300px'}} src="/responsive-gif-c.gif" className="w-full object-cover" />
            </div>
          </div>

        </div>
      </div>
    </section>

    <section className=" py-8 mt-16" >
      <div className="max-w-6xl mx-auto">
        
        <h2 className="md:w-1/2 px-4 md:px-0 leading-snug text-3xl font-medium">Everything you need to send dynamic emails to your users</h2>
      
      
        <div className="w-full flex flex-col md:flex-row mt-12">
          <div style={{ backgroundColor:"#ffd02f"}} className=" w-full md:w-5/12 px-8 py-8 flex flex-col justify-center">
            <div><img alt="personalized emails" className="w-full" src="/email-variable.svg" /></div>
            <div className="mt-8">
              <h3 className="md:text-3xl text-2xl font-medium">Personalize your Emails</h3>
              <p className="font-normal text-base mt-2 text-gray-700">Send personalized emails to every users using dynamic variables. </p>
            </div>
          </div>

          <div className="bg-blue-700 w-full md:w-7/12 py-8 md:ml-8">
            <div><img alt="editor data structures" className="w-10/12" src="/iterator-sample.svg" /></div>
            <div className="mt-8 px-8">
              <h3 className="md:text-3xl text-2xl text-white font-medium">Work with different data structures</h3>
              <p className="font-normal text-gray-200 text-base mt-2 ">With a click of a button, you can turn any design module into an iterative data module. Send a list of products for an invoice or group of new supporters in a community through the API and Emailkick will convert it into stackable blocks.</p>
            </div>
          </div>


        </div>

        




      </div>
    </section>


    <section className="feat-section mt-0 md:py-12 py-2" >

<div className="max-w-6xl mx-auto">
    {/* <div className="relative z-0">
        <img alt="" className="absolute transform scale-100 -mt-24" src="/images/blurred_2.png" />
        <img alt="" className="absolute transform md:scale-150 right-2 -mt-24 opacity-50" src="/images/blurred_2.png" />
    </div>   */}
  <div className="flex items-center">
    <div className="w-5/12 md:pr-4">  
      <h3 className="mb2 display strong md:text-4xl  text-2xl leading-tight highlight-text">One place to manage all your email templates</h3>
      <p className="quiet display md:text-lg text-base line-height-4 mb2">From Welcome emails to forget passwords to invoices. Manage all your templates in one place. Change anytime on the fly without changing your applications backend code.</p>
      <Link className="text-blue-700 text-base hover:text-blue-800 hover:underline" to="/ses-template-manager/">Learn more about template manager →</Link>

    </div>
    <div className="w-6/12">
        <img alt="emailkick template manager" src="/emails-02.png" className="w-full" />
    </div>
  </div>
  

</div>

</section>




     <section className="feat-section" style={{ marginTop: '80px' , }}>
      <div className="max-width-4 mx-auto">
        <div className="row feat-pnk p4 center">
        
            <h2 className="mb2 display strong xhuge line-height-2  highlight-text">Connect to AWS SES and start sending good looking emails</h2>
            <h3 className=" display line-height-3 mb2">Also, Cut your email delivery cost to a fraction using Amazon Web Service Simple Email Service. It takes just seconds to connect.</h3>
      
            <div className="mt-3">
              <a rel="nofollow" href='https://app.emailkick.com/register' className="mt-2 inline-block bg-red-500 hover:bg-red-600 text-white strong px-8 py-4 text-lg font-medium rounded-xl  duration-100 shadow-lg">Get started for free</a>
            </div>
        </div>
      </div>
    </section>




  </Layout>
)

export default IndexPage
